@import './reset.css';


.hover-portfolio-menu:hover {

  color: #FF974D;
  text-decoration: underline;

}


.hover-block-four-navigation:hover {

  color: #FF974D;
  text-decoration: underline;

}